// extracted by mini-css-extract-plugin
export var black = "landing-block-module--black--cAGaP";
export var block = "landing-block-module--block--sjCel";
export var gray = "landing-block-module--gray--S4npI";
export var orange = "landing-block-module--orange--4940q";
export var pictureWrapper = "landing-block-module--pictureWrapper--HfowP";
export var round = "landing-block-module--round--z3lNl";
export var text = "landing-block-module--text--6fLBB";
export var textContent = "landing-block-module--textContent--2-Xrl";
export var title = "landing-block-module--title--QxyII";
export var white = "landing-block-module--white--F3jOF";
export var withShadow = "landing-block-module--withShadow--n83G3";
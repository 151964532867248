import * as React from 'react';
import { Link } from 'gatsby';

import * as Text from '../localisation';
import { Url } from '../url';

import * as classes from './footer-get-started.module.css';

function FooterGetStarted() {
  return (
    <div className={classes.callToActionBlock}>
      <h2 className={classes.callToActionTitle}>
        {Text.FooterCallToActionTitle}
      </h2>

      <div className={classes.callToActionInner}>
        <Link to={Url.GetStarted} className={classes.callToActionButton}>
          {Text.GetStarted}
        </Link>
      </div>
    </div>
  )
}

export default FooterGetStarted;

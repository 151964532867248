import * as React from 'react';

import TopMenu from './top-menu';
import Footer from './footer';

import * as classes from './secondary-wide-layout.module.css';

function SecondaryPageLayout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <TopMenu />
      <main className={classes.pageLayout}>
        <div className={classes.content}>
          {children}
        </div>

        <div className={classes.footerWrapper}>
          <Footer />
        </div>
      </main>
    </>
  );
}

export default SecondaryPageLayout;

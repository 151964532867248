import * as React from 'react';

import * as classes from './landing-block.module.css';

export enum LandingBlockStyle {
  White,
  Gray,
  Black,
  Orange,
}

type LandingBlockProps = {
  title: React.ReactNode,
  text: React.ReactNode,
  picture: React.ReactNode,
  style: LandingBlockStyle,
  pictureShadowShown?: boolean,
  pictureBorderRound?: boolean,
}

function LandingBlock(props: LandingBlockProps) {
  const {
    title,
    text,
    picture,
    style,
    pictureShadowShown = false,
    pictureBorderRound = false,
  } = props;

  let styleClassName = '';

  switch (style) {
    case LandingBlockStyle.White:
      styleClassName = classes.white;
      break;
    case LandingBlockStyle.Gray:
      styleClassName = classes.gray;
      break;
    case LandingBlockStyle.Black:
      styleClassName = classes.black;
      break;
    case LandingBlockStyle.Orange:
      styleClassName = classes.orange;
      break;
  }

  return (
    <section className={`${classes.block} ${styleClassName}`}>
      <div className={classes.textContent}>
        <h2 className={classes.title}>
          {title}
        </h2>
        <div className={classes.text}>
          {text}
        </div>
      </div>
      <div className={`${classes.pictureWrapper} ${pictureShadowShown ? classes.withShadow : ''} ${pictureBorderRound ? classes.round : ''}`}>
        {picture}
      </div>
    </section>
  )
}

export default LandingBlock;

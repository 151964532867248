import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import SecondaryWideLayout from '../../components/secondary-wide-layout';
import LandingHeader, { LandingHeaderStyle } from '../../components/landing-header';
import LandingBlock, { LandingBlockStyle } from '../../components/landing-block';
import FooterGetStarted from '../../components/footer-get-started';
import Meta from '../../components/meta';

import * as Text from '../../localisation';
import { Url } from '../../url';

import Projects from '../../images/managers-projects.svg';
import Reports from '../../images/managers-reports.svg';

function Managers() {
  return (
    <>
      <Meta
        title={Text.ManagersPageTitle}
        url={Url.UseCaseManagers}
      />

      <SecondaryWideLayout>
        <LandingHeader
          style={LandingHeaderStyle.BlackTitle}
          title={Text.ManagersHeaderTitle}
          text={Text.ManagersHeaderText}
        />

        <LandingBlock
          title={Text.ManagersGeneralTitle}
          text={Text.ManagersGeneralText}
          picture={(
            <StaticImage
              src="../../images/managers-app-preview.png"
              alt={Text.ManagersGeneralPictureAlt}
              layout="fullWidth"
            />
          )}
          pictureShadowShown
          pictureBorderRound
          style={LandingBlockStyle.Gray}
        />

        <LandingBlock
          title={Text.ManagersProjectsTitle}
          text={Text.ManagersProjectsText}
          picture={(
            <img
              src={Projects}
              alt={Text.ManagersProjectsPictureAlt}
            />
          )}
          style={LandingBlockStyle.White}
        />

        <LandingBlock
          title={Text.ManagersPeopleTitle}
          text={Text.ManagersPeopleText}
          picture={(
            <StaticImage
              src="../../images/managers-collection-preview.png"
              alt={Text.ManagersPeoplePictureAlt}
              layout="fullWidth"
            />
          )}
          pictureShadowShown
          pictureBorderRound
          style={LandingBlockStyle.Orange}
        />

        <LandingBlock
          title={Text.ManagersMeetingsTitle}
          text={Text.ManagersMeetingsText}
          picture={(
            <StaticImage
              src="../../images/managers-meeting-preview.png"
              alt={Text.ManagersMeetingsPictureAlt}
              layout="fullWidth"
            />
          )}
          pictureShadowShown
          pictureBorderRound
          style={LandingBlockStyle.Black}
        />

        <LandingBlock
          title={Text.ManagersReportsTitle}
          text={Text.ManagersReportsText}
          picture={(
            <img
              src={Reports}
              alt={Text.ManagersReportsPictureAlt}
            />
          )}
          style={LandingBlockStyle.Gray}
        />

        <FooterGetStarted />
      </SecondaryWideLayout>
    </>
  );
}

export default Managers;

import * as React from 'react';

import * as classes from './landing-header.module.css';

export enum LandingHeaderStyle {
  GreenTitle,
  BlackTitle,
  WhiteTitle,
}

type LandingHeaderProps = {
  title: React.ReactNode,
  text: React.ReactNode,
  style: LandingHeaderStyle,
}

function LandingHeader(props: LandingHeaderProps) {
  const {
    title,
    text,
    style,
  } = props;

  let styleClassName = '';

  switch (style) {
    case LandingHeaderStyle.GreenTitle:
      styleClassName = classes.greenTitle;
      break;
    case LandingHeaderStyle.BlackTitle:
      styleClassName = classes.blackTitle;
      break;
    case LandingHeaderStyle.WhiteTitle:
      styleClassName = classes.whiteTitle;
      break;
  }

  return (
    <div className={`${classes.header} ${styleClassName}`}>
      <h1 className={classes.title}>
        {title}
      </h1>
      <div className={classes.text}>
        {text}
      </div>
    </div>
  )
}

export default LandingHeader;
